<template>
  <div class="main-content" style="
    padding-bottom: 10vh !important;
      width: 100%;
      background:  #000;
    ">

     
    <div class="m-3 mx-3 mt-4 mb-5" style="padding-top: 4vh">

      <div class="mt-5">
        <h1 class="text-44 font-weight-semibold text-outfit">{{ $t("your_gateway_to") }}<br/> <span class="text-light-green font-weight-bold">{{ $t("future_finance") }}</span></h1>
      </div>

      <div class="my-4" id="about_etnex">
        <img src="../../../assets/images/etnex_home/home_1.png" alt="" />
      </div>
      <div class="my-4">
        <h1 class="font-weight-semibold text-outfit">{{ $t("about") }} <span class="text-light-green font-weight-bold">ETNEX</span></h1>
        <p class="text">{{ $t("about_desc") }}</p>
      </div>

      <div class="my-4" id="about_nexa">
        <img src="../../../assets/images/etnex_home/home_2.png" alt="" />
      </div>
      <div class="my-4">
        <h1 class="font-weight-semibold">{{ $t("about") }} <span class="text-light-green font-weight-bold">NEXA</span></h1>
        <p class="text">{{ $t("about_desc2") }}</p>
      </div>

      <div class="etnex-package-container black-dark-blue-card mb-3" style="background: #1E1D1D;">
        <img src="../../../assets/images/etnex_home/nexa_1.png" alt="" />
        <h2 class="font-weight-bold text-light-green mt-3">{{ $t("data_analysis") }} </h2>
        <p class="text">{{ $t("nexa_desc") }}</p>
      </div>

      <div class="etnex-package-container black-dark-blue-card mb-3" style="background: #1E1D1D;">
        <img src="../../../assets/images/etnex_home/nexa_2.png" alt="" />
        <h2 class="font-weight-bold text-light-green mt-3">{{ $t("arbitrage_trading") }} </h2>
        <p class="text">{{ $t("nexa_desc_2") }}</p>
      </div>

      <div class="etnex-package-container black-dark-blue-card mb-3" style="background: #1E1D1D;">
        <img src="../../../assets/images/etnex_home/nexa_3.png" alt="" />
        <h2 class="font-weight-bold text-light-green mt-3">{{ $t("high_frequency_trading") }} </h2>
        <p class="text">{{ $t("nexa_desc_3") }}</p>
      </div>

      <div class="etnex-package-container black-dark-blue-card mb-3" style="background: #1E1D1D;">
        <img src="../../../assets/images/etnex_home/nexa_4.png" alt="" />
        <h2 class="font-weight-bold text-light-green mt-3">{{ $t("risk_management") }} </h2>
        <p class="text">{{ $t("nexa_desc_4") }}</p>
      </div>

      <div class="etnex-package-container black-dark-blue-card mb-3" style="background: #1E1D1D;">
        <img src="../../../assets/images/etnex_home/nexa_5.png" alt="" />
        <h2 class="font-weight-bold text-light-green mt-3">{{ $t("flexible_trading") }} </h2>
        <p class="text">{{ $t("nexa_desc_5") }}</p>
      </div>


      <div class="scroll-container">
            <img src="../../../assets/images/etnex_home/home_wire_en.png" style="min-width:100vh;"/>
  
      </div>
      
      <!--<div class="mt-5" id="pairings">
        <h1 class="font-weight-semibold">{{ $t("market") }} <span class="text-light-green font-weight-bold">{{ $t("pairings") }}</span></h1>
      </div>

      <div class="etnex-card black-dark-blue-card mt-3 mb-4">
          <div class="purchase-content">
              <div class="asset-info pt-2">
                <p class="font-weight-bold text-18 text-primary">{{ $t("market") }}</p>
              <div class="price-list">
                <div class="head mb-3">
                    <b-col cols="4" class="p-0">
                      <div class="logo"></div>
                    </b-col>
                    <b-col cols="4" class="p-0">
                      <div class="logo text-white text-12"><img src="../../../assets/images/etnex_home/btc.png" alt="" style="max-width: 18%;"/> BTCUSDT</div>
                    </b-col>
                    <b-col cols="4" class="p-0">
                      <div class="logo text-white text-12"><img src="../../../assets/images/etnex_home/eth.png" alt="" style="max-width: 18%;"/> ETHUSDT</div>
                    </b-col>
                </div>
                <div class="exchange mb-3">
                    <b-col cols="4" class="p-0">
                      <div class="logo" style="max-width: 80%;"><img src="../../../assets/images/etnex_home/binance.png" alt="" /></div>
                    </b-col>
                    <b-col cols="4" class="p-0">
                      <div class="crypto">
                        <span class="price text-white">{{ binance_btcprice.substring(0,8) }}</span>
                    </div>
                    </b-col>
                    <b-col cols="4" class="p-0">
                      <div class="crypto">
                        <span class="price text-white">{{ binance_ethprice.substring(0,7) }}</span>
                    </div>
                    </b-col>
                </div>
                <div class="exchange mb-3">
                    <b-col cols="4" class="p-0">
                      <div class="logo" style="max-width: 70%;"><img src="../../../assets/images/etnex_home/kucoin.png" alt="" /></div>
                    </b-col>
                    <b-col cols="4" class="p-0">
                      <div class="crypto">
                        <span class="price text-white">{{ kucoin_btcprice }}</span>
                    </div>
                    </b-col>
                    <b-col cols="4" class="p-0">
                      <div class="crypto">
                        <span class="price text-white">{{ kucoin_ethprice }}</span>
                    </div>
                    </b-col>
                    
                </div>

                <div class="exchange mb-3">
                    <b-col cols="4" class="p-0">
                      <div class="logo" style="max-width: 40%;"><img src="../../../assets/images/etnex_home/bybit.png" alt="" /></div>
                    </b-col>
                    <b-col cols="4" class="p-0">
                      <div class="crypto">
                        <span class="price text-white">{{ bybit_btcprice }}</span>
                    </div>
                    </b-col>
                    <b-col cols="4" class="p-0">
                      <div class="crypto">
                        <span class="price text-white">{{ bybit_ethprice }}</span>
                    </div>
                    </b-col>
                    
                </div>
            </div>

              </div>
          </div>
      </div> -->
      <div class="mt-5" id="tokenomics">
        <h1 class="font-weight-semibold">ETNEX <span class="text-light-green font-weight-bold">{{ $t("tokenomics") }}</span></h1>
      </div>
      <div class="mt-3 mb-4 d-flex flex-column justify-content-center align-items-center">
        <img src="../../../assets/images/etnex_home/Figpie.png" alt="" width="80%"/>
        <div class="exchange text px-5 mt-4 mb-3">
          <b-row>
            <b-col cols="12" class="text-14 " v-for="(item, index) in items" :key="index">
              <b-row class="align-items-center">
                <b-col cols="1" class="p-0">
                  <span class="circle" :style="{ borderColor: item.color }"></span>
                </b-col>
                <b-col cols="7" class="p-0">
                  <div class="crypto">
                    <span class="name text-white">{{ item.name }}</span>
                  </div>
                </b-col>
                <b-col cols="4" class="p-0 text-right">
                  <div class="crypto">
                    <span class="percentage text-white">{{ item.percentage }}</span>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>

      <div class="my-4" id="how_to_start_with">
        <h1 class="font-weight-semibold">{{ $t("how_to_start_with") }} <span class="text-light-green font-weight-bold">ETNEX NEXA?</span></h1>
      </div>

      <div class="etnex-package-container black-dark-blue-card py-3 px-5 mb-3 d-flex flex-column align-items-center" style="background: #1E1D1D;">
        <img src="../../../assets/images/etnex_home/start_nexa.png" class="py-3" alt="" />
        <p class="text text-20 font-weight-semibold text-center">{{ $t("register_an_account_in_ETNEX_dapp") }}</p>

        <img src="../../../assets/images/etnex_home/start_nexa_2.png" class="py-3" alt="" />
        <p class="text text-20 font-weight-semibold text-center">{{ $t("purchase_ETNEX_token") }}</p>

        <img src="../../../assets/images/etnex_home/start_nexa_3.png" class="py-3" alt="" />
        <p class="text text-20 font-weight-semibold text-center" style="line-height: 24px;">{{ $t("subscribe_to_NEXA") }} <br/><span class="mb-0">{{ $t("Let_NEXA_do_the_work") }}</span></p>

        <img src="../../../assets/images/etnex_home/start_nexa_4.png" class="py-3" alt="" />
        <p class="text text-20 font-weight-semibold text-center">{{ $t("wait_for_profit_everyday!") }}</p>
      </div>

      <div class="w-50 mx-auto mt-5 pt-5 mb-2">
        <img src="../../../assets/images/logo.png" alt="" />
      </div>

      <div class="mt-4 mb-3">
          <b-row class="py-2 justify-content-center">
            <b-col cols="4" class="p-0">
              <p class="text text-16 text-center" @click="scrollTo('about_etnex')">{{ $t("home") }}</p>
            </b-col>
            <b-col cols="4" class="p-0">
              <p class="text text-16 text-center" @click="scrollTo('about_etnex')">{{ $t("about") }} ETNEX</p>
            </b-col>
            <b-col cols="4" class="p-0">
              <p class="text text-16 text-center" @click="scrollTo('about_nexa')">{{ $t("about") }} NEXA</p>
            </b-col>
          </b-row>
          <b-row class="py-2 justify-content-center">
            <b-col cols="4" class="p-0">
              <p class="text text-16 text-center" @click="scrollTo('pairings')">{{ $t("market_pairings") }}</p>
            </b-col>
            <b-col cols="4" class="p-0">
              <p class="text text-16 text-center" @click="scrollTo('tokenomics')">{{ $t("tokenomics") }}</p>
            </b-col>
           
          </b-row>
          <div class="py-2 justify-content-center">
            <p class="text text-16 text-center" @click="scrollTo('how_to_start_with')">{{ $t("how_to_get_start") }}</p>
          </div>

          <b-row class="py-2 px-5 justify-content-center">
            <b-col cols="3" class="text-center p-0">
              <a href="https://twitter.com/ETNEX_OFFICIAL">
              <img src="../../../assets/images/etnex_home/footer_twitter.png" />
              </a>
            </b-col>
            <b-col cols="3" class="text-center p-0">
              <a href="https://www.instagram.com/etnex.official/">
              <img src="../../../assets/images/etnex_home/footer_insta.png" />
              </a>
            </b-col>
            <b-col cols="3" class="text-center p-0">
              <a href="https://www.facebook.com/etnex.official/">
              <img src="../../../assets/images/etnex_home/footer_fb.png" />
              </a>
            </b-col>
            <b-col cols="3" class="text-center p-0">
              <a href="https://www.youtube.com/channel/UC0LSx9X3grBikOet4MQW3AA">
              <img src="../../../assets/images/etnex_home/footer_youtube.png" />
              </a>
            </b-col>
          </b-row>

          <b-row class="py-4 justify-content-center text-center">
            <b-col cols="auto" class="p-0 d-flex align-items-center justify-content-end">
              <p class="text text-12">{{ $t("privacy") }}</p>
            </b-col>
            
            <b-col cols="auto" class="p-0 d-flex align-items-center">
              <div class="vertical-line mx-3 mb-3"></div>
            </b-col>
            
            <b-col cols="auto" class="p-0 d-flex align-items-center justify-content-start">
              <p class="text text-12">{{ $t("tnc") }}</p>
            </b-col>
          </b-row>

          <div class="justify-content-center">
            <p class="text text-16 text-center">{{ $t("copyright") }}</p>
          </div>

        </div>
    
    </div>
    <Dialog ref="msg"></Dialog>
  </div>
</template>
<script>
import {
  getMemberInfo,getBitcoinPrice,getDownloadLink
} from "../../../system/api/api";
import { mapGetters, mapActions } from "vuex";
import Dialog from "../../../components/dialog.vue";
import { handleError } from "../../../system/handleRes";
export default {
  computed: {
    ...mapGetters([
      "getPoint1",
    ]),
  },
  components: {
    Dialog
  },
  data() {
    return {
      member: {
        team_member: 0,
        team_sales: 0,
      },
      dfi_price: 0,
      isLoading: false,
      rank: "",
      sec_password: "",
      binance_btcprice: "",
      bybit_btcprice: "",
      kucoin_btcprice: "",
      binance_ethprice: "",
      bybit_ethprice: "",
      kucoin_ethprice: "",
      priceUpdater: null,
      market_price: 0,
      items: [
        { color: '#042C24', name: 'Pre-sales', percentage: '10%' },
        { color: '#D97BF2', name: 'AI Trading Rewards', percentage: '30%' },
        { color: '#9E7BF2', name: 'IoT Mining Rewards', percentage: '20%' },
        { color: '#2DCC70', name: 'Marketing Reserved', percentage: '10%' },
        { color: '#F1C40F', name: 'Future Development Reserved', percentage: '15%' },
        { color: '#E74C3C', name: 'Foundation Reserved', percentage: '5%' },
        { color: '#17A589', name: 'DEX 2.0 Liquidity Pool', percentage: '10%' }
      ]
    };
  },
  methods: {
    ...mapActions([
      "updateP1",
    ]),
    scrollTo(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        const yOffset = -80;
        const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    },
    clipboardSuccessHandler() {
      this.$refs.msg.makeToast("success", this.$t("copied"));
    },
    clipboardErrorHandler() {
      //// console.log('error', value)
    },
    memberInfo() {
      var result = getMemberInfo();
      var self = this;
      result
        .then(function (value) {
          self.member = value.data.data;
          self.updateP1(self.member.point1);
          self.inv_link = value.data.data.username;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    cryptoPrice() {
      var result = getBitcoinPrice();
      var self = this;
      result
        .then(function (value) {
          self.binance_btcprice = value.data.data.btc.binance.price;
          self.bybit_btcprice = value.data.data.btc.bybit.price;
          self.kucoin_btcprice = value.data.data.btc.kucoin.price;
          self.binance_ethprice = value.data.data.eth.binance.price;
          self.bybit_ethprice = value.data.data.eth.bybit.price;
          self.kucoin_ethprice = value.data.data.eth.kucoin.price;
        })
        .catch(function (error) {
          console.log(error);
        });

        var result_lookup = getDownloadLink();
        result_lookup
        .then(function (value) {
          self.market_price = value.data.data.system.etnex_price;
        })
        .catch(function (error) {
          self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
        });
    },
  },
  mounted() {
    this.priceUpdater = setInterval(() => {
      this.cryptoPrice();
    }, 5000);
    window.addEventListener("beforeunload", this.unload);
  },
  created() {
    this.memberInfo();
    this.cryptoPrice();
  },
  beforeDestroy() {
    if (this.priceUpdater) {
      clearInterval(this.priceUpdater);
    }
  },
};
</script>

<style scoped>
.price-list {
    display: flex;
    flex-direction: column;
}

.head, .exchange {
    display: flex;
    justify-content: space-between;
    align-items: left;
    width: 100%;
}


.space-holder {
    flex-grow: 1; /* Make the space holder flexible */
    visibility: hidden; /* Make the space holder invisible */
}

.crypto {
    flex: 1; /* Assign flex grow to crypto blocks to distribute space evenly */
    justify-content: center; /* Center price horizontally in its container */
    margin: 0 10px; /* Add margin if needed for spacing */
}


.sell-etnex-container {
    background: var(--bg-shade, linear-gradient(180deg, rgba(52, 58, 66, 0.53) 0%, rgba(0, 0, 0, 0.81) 100%));
    max-width: 100%;
    margin: auto;
    color: white;
    padding: 12px;
    font-family: 'Poppins', sans-serif;
    border-radius: 8px;
    border: 1px solid var(--Neon-Blue, #0BDDCC);
  }
  .sell-card {
  border-radius: 12px;
  color: #fff;
  padding: 8px;
  font-family: 'Poppins', sans-serif;
}

.card-header {
  display: flex;
  align-items: center;
  padding: 0px !important;
}
.new_logo {
  max-width: 65%;
  /* margin-right: 0.5rem; */
}


.text{
  color: var(--Main, #FFF);
  font-family: Poppins;
  font-style: normal;
}
.card {

  background: var(--bg-shade, linear-gradient(180deg, rgba(52, 58, 66, 0.53) 0%, rgba(0, 0, 0, 0.81) 100%));
  max-width: 100%;
  margin: auto;
  color: white;
  padding: 12px;
  font-family: 'Poppins', sans-serif;
  border-radius: 8px;
  border: 1px solid var(--Neon-Blue, #0BDDCC);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.text-container {
  flex-grow: 1;
}

.currency-name {
font-size: 20px;
font-weight: 600;
line-height: 21px;
}

.currency-price{
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
}


/* .address-input label,
.address-input input {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.address-input input {
  width: calc(100% - 30px);
  background: #333;
  border: none;
  padding: 10px;
  border-radius: 5px;
  color: #ccc;
}

.address_input{
  border-radius: 7px !important;
  border: 1px solid var(--Neon-Blue, #0BDDCC) !important;
  background: var(--bg-shade, linear-gradient(180deg, rgba(52, 58, 66, 0.53) 0%, rgba(0, 0, 0, 0.81) 100%)) !important;
}

.address_text{
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
} */
.input_copy_wrapper{
  /* width: 450px;
  padding: 50px; */
  margin : 0 auto;
}
.input_copy {
    padding: 12px;
    border-radius: 7px !important;
    border: 1px solid var(--Neon-Blue, #0BDDCC) !important;
    background: var(--bg-shade, linear-gradient(180deg, rgba(52, 58, 66, 0.53) 0%, rgba(0, 0, 0, 0.81) 100%)) !important;
    font-size: .8em;
}

.input_copy .icon {
    display: block;
    max-width: 25px;
    cursor: pointer;
    float: right;
}

.input_copy .icon img{
  max-width: 25px;
}
.input_copy .txt {
    margin-top: 4px;
    width: 80%;
    display: inline-block;
    overflow: hidden;
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}


/* click animation */

.flashBG {
    animation-name: flash;
    animation-timing-function: ease-out;
    animation-duration: 1s;
}

</style>